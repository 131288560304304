import React, { Component } from "react";
import "./GroupInfo.css";

function formatCourseId(courseId = "") {
	return "M" + (courseId + "").padStart(4, "0");
}

class GroupInfo extends Component {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}

	componentDidMount() {
		window.scrollTo({
			top: this.ref.current.offsetTop - window.innerHeight * 0.05,
			behavior: "smooth",
		});
	}

	render() {
		const group = this.props.group;
		return (
			<div className="GroupInfo" ref={this.ref}>
				<div className="text-and-image">
					<div className="info-text">
						<h1 className="title">{(group.name + "").toUpperCase()}</h1>
						<div className="period">
							<div className="square" />
							<p className="number">Blok {group.period}</p>
						</div>

						<p className="description-title">Module beschrijving:</p>

						<p>{group.description}</p>

						<button
							className="enroll-button"
							onClick={() => {
								let subjectName;
								switch (group.type) {
									case "adventure":
										subjectName = "Avonturen";
										break;
									case "traject":
										subjectName = "Trajecten";
										break;
									case "parcours":
									default:
										subjectName = group.subjectName;
										break;
								}
								window.open(
									`https://app.q-highschool.nl/aanbod?tab=${subjectName}&blok=${group.period}&leerjaar=${group.schoolYear}`
								);
							}}
						>
							AANMELDEN
						</button>
					</div>
					<div
						className="image"
						style={{
							background: `url(https://q-highschool.nl/images/thumbnails/${
								group.type === "parcours"
									? formatCourseId(Object.values(group.courses)[0]?.id)
									: group.id
							}.jpg), url(https://q-highschool.nl/images/thumbnails/default.jpg) no-repeat`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundPosition: "center center",
						}}
					/>
					<button className="close-button" onClick={this.props.onClose} />
				</div>
				<div className="extra-info">
					<p className="extra-info-text">
						<b>Dag:</b> {group.day}{" "}
					</p>
					{/* <p className = 'extra-info-text'><b>Tijd:</b> {group.time}</p> */}
					<p className="extra-info-text">
						<b>Doelgroep:</b> {group.enrollableFor}{" "}
					</p>
				</div>
			</div>
		);
	}
}

export default GroupInfo;
