const api = "https://api.q-highschool.nl";

export async function fetchGroups(handleError) {
	const {
		enrollmentBlock,
		enrollmentPeriod,
		enrollmentRound,
		enrollmentSchoolYear,
	} = await fetch(api + "/api/sync/constants").then((res) => res.json());

	const isFuture = ({ type, period, schoolYear }) => {
		if (!schoolYear || !period) return false;
		let theEnrollmentPeriod;
		switch (type) {
			case "traject":
				theEnrollmentPeriod = enrollmentPeriod;
				break;
			case "adventure":
				theEnrollmentPeriod = enrollmentRound;
				break;
			case "parcours":
			default:
				theEnrollmentPeriod = enrollmentBlock;
				break;
		}
		if (schoolYear === enrollmentSchoolYear) {
			return Number(period) >= Number(theEnrollmentPeriod);
		} else {
			return (
				Number(schoolYear.slice(0, 4)) >
				Number(enrollmentSchoolYear.slice(0, 4))
			);
		}
	};

	return fetch(api + "/api/groups")
		.then((res) => res.json())
		.then((groups) => {
			let groupsPerSubject = {};
			Object.values(groups)
				.filter(isFuture)
				.filter(({ enrollable }) => enrollable)
				.forEach((group) => {
					if (group.type === "parcours") {
						Object.values(group.courses).forEach((course) => {
							const subjectName = course.subject.name;
							if (!groupsPerSubject[subjectName]) {
								groupsPerSubject[subjectName] = {};
							}
							groupsPerSubject[subjectName][group.id] = group;
						});
					} else if (group.type === "adventure") {
						if (!groupsPerSubject["Avonturen"]) {
							groupsPerSubject["Avonturen"] = {};
						}
						groupsPerSubject["Avonturen"][group.id] = group;
					} else if (group.type === "traject") {
						if (!groupsPerSubject["Trajecten"]) {
							groupsPerSubject["Trajecten"] = {};
						}
						groupsPerSubject["Trajecten"][group.id] = group;
					}
				});
			return groupsPerSubject;
		})
		.catch(handleError);
}

export async function fetchSubjectInformation(handleError) {
	return fetch(api + "/api/subjects")
		.then((res) => res.json())
		.then((subjects) => {
			let informationPerSubject = {};
			Object.values(subjects).forEach((s) => {
				informationPerSubject[s.name] = s.description;
			});
			return informationPerSubject;
		})
		.catch(handleError);
}
