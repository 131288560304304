import React, { Component } from "react";
import "./Group.css";

function formatCourseId(courseId = "") {
	return "M" + (courseId + "").padStart(4, "0");
}

class Group extends Component {
	render() {
		const { selected, type, large, text, onClick, courseId, groupId } =
			this.props;
		const color = this.props.color;
		return (
			<div
				className={
					"Group" +
					(large ? " large" : "") +
					(selected ? " selected" : "") +
					" " +
					color
				}
				onClick={onClick}
				style={{
					backgroundImage: `url(https://q-highschool.nl/images/thumbnails/${
						type === "parcours" ? formatCourseId(courseId) : groupId
					}.jpg), url(https://q-highschool.nl/images/thumbnails/default.jpg)`,
				}}
			>
				<img
					src={`https://q-highschool.nl/images/thumbnails/${formatCourseId(
						courseId
					)}.jpg`}
					alt=""
					style={{ display: "none" }}
					loading="lazy"
				/>
				<img
					src={"https://q-highschool.nl/images/thumbnails/default.jpg"}
					alt=""
					style={{ display: "none" }}
					loading="lazy"
				/>
				<h2 className="text">{text}</h2>
				<img className="q-logo" src="q.svg" alt="" />
				{selected && <div className="arrow" />}
			</div>
		);
	}
}

export default Group;
